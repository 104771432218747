
<template>
  <div id="login">
    <div class="uk-container">
      <div class="uk-flex uk-flex-center">
        <div class="uk-width-xlarge@m uk-margin-xlarge-top">
          <div class="uk-card uk-card-small uk-card-default uk-card-body">
            <h3 class="uk-card-title uk-margin-top uk-text-center">登入</h3>
            <div class="uk-card-body">
              <form class="uk-form-stacked" @submit.prevent="login">
                <div class="uk-margin">
                  <label class="uk-form-label">電子信箱</label>
                  <div class="uk-form-controls">
                    <input
                      class="uk-input"
                      type="email"
                      name="email"
                      placeholder="Email address..."
                      v-model="email"
                    />
                  </div>
                </div>
                <div class="uk-margin">
                  <label class="uk-form-label">密碼</label>
                  <div class="uk-form-controls">
                    <input
                      class="uk-input"
                      type="password"
                      placeholder="password..."
                      v-model="password"
                    />
                  </div>
                </div>
                <div v-if="error" class="uk-margin">
                  <p class="uk-text-danger uk-text-center">{{ error }}</p>
                </div>
                <div class="uk-margin-medium-top uk-text-center">
                  <div @click="login"
                    class="uk-button-primary uk-button uk-width-1-1"
                    type="submit"
                  >
                    登入
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Login",
  metaInfo: {
    title: "登入",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content: "",
      },
    ],
  },
  data() {
    return {
      email: "",
      password: "",
      error: null,
    };
  },
  methods: {
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          //   alert("Successfully logged in");
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          this.error = error.message;
          //   alert(error.message);
        });
    },
  },
};
</script>